<!-- begin::Footer -->
<div class="m-footer mt-4 m--custom-footer doNotPrint">
	<div class="container">
		<!-- <div class="row text-center row-py-padding text-xl-start">
			<div class="col-sm-6 mb-3">
				<div class="footer-content">
					<p>
						OpenRoad Lending, LLC
					</p>
				</div>
			</div>

			<div class="col-sm-3 mb-3">
				<div class="footer-content">
					<div>Email:<br> LoanHelp@OpenRoadLending</div>
				</div>
			</div>

			<div class="col-sm-3 mb-3">
				<div class="footer-content">
					<div>Phone:<br><a class="mobile" href="tel:+1-888-628-2289"> (888) 536-3024</a></div>
				</div>
			</div>
		</div> -->

		<div class="row">
			<div class="col-sm-4 order-1">
				<div class="footer-copyright"> &copy; {{currentYear}} OpenRoad Lending. All rights reserved.</div>
			</div>
		</div>
	</div>
</div>
<!-- end::Footer -->

