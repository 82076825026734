export const config: any = {
	applicationFlow: 2,
	existingMemberPrompt: true,
	phoneNumber: '888-536-3024',
	institutionName: 'OpenRoad Lending',
	logoName: 'https://s3.amazonaws.com/creditsnapimages/ORL/logo.png',
	productSelection: false, // true for first page to show product selection with images
	multiSteps: false,
	stepsLocation: '', //top or "blank for no progress bar"
	imageFlow: false,
	imageForCollateral: false,
	branding: {},
	askOTP: false,
	otpVerifyChannel: 'SMS', // SMS or SMS_EMAIL
	stepsCount: 3,
	showRatingsWidgets: true,
	ratingHeaderIframeURL: 'https://trustspot.io/merchant/TrustModule/banner_2/OpenRoad-Lending',
	ratingFooterIframeURL: 'https://trustspot.io/merchant/TrustModule/carousel_2/OpenRoad-Lending',
	ratingjsURL: null,
	monthlyIncome: true,
	iconHueDegree: 275,
	allowCoApplicant: false,
	steps: {
		1: {
			title: 'creditsnap Details',
			fields: {
				first_name: true,
				last_name: true,
				email: true,
				mobile_number: true,
				income: true,
				employment_length: true,
				rent_or_own: true,
				housing_cost: true,
				address_details: true,
				date_of_birth: true,
				citizenship: true,
				graduation_level: true,
				MLA: true,
				misc: true
			}
		},
		2: {
			title: 'Test Details',
			fields: {
				income: true,
				employment_length: true,
				rent_or_own: true,
				housing_cost: true
			}
		},
		3: {
			title: 'Personal details',
			fields: {
				income: true,
				employment_length: true,
				rent_or_own: true,
				housing_cost: true
			}
		}
	},
	consentsConfig: {
		web: {
			consent1_individual: {
				text: `By checking this box, providing the information in this application and clicking on the "Continue" button, the applicant and any co-applicant ("I," "me," and "my") each agree that: (1) I received and read the
				<a href='https://www.openroadlending.com/us/state-specific-disclosures/' target="_blank">State Disclosures</a> and the <a href='https://www.openroadlending.com/us/privacylegal/' target="_blank">OpenRoad Lending Privacy Policy</a>;
				(2) I received, read, and agree to the <a href='https://www.openroadlending.com/us/consent-electronic-documents/' target="_blank">Consent for Consent for Electronic Disclosures</a> and all other
				<a href='https://www.openroadlending.com/terms-conditions/' target="_blank">Terms and Conditions</a> for this application shown above; (3) I authorize OpenRoad Lending to pass my application to other Lenders who may be able to assist
				me in refinancing; (4) I am granting OpenRoad Lending and its Lender partners permission to access my credit report to determine if I qualify for
				refinancing my existing auto loan, and I understand that I could have multiple inquiries on my credit report through this process; and (5) I
				provide express consent to receive prerecorded calls/texts or live agent calls regarding this application and financial services or other credit
				related offers from OpenRoad Lending or one if its third party lenders or affiliates at any number I have provided on this application
				(consent to be contacted is not a condition to purchase services).`,
				required: true,
				consents: ['Marketing', 'SoftInQuiry']
			},
			consent2_individual: {
				text: `Applicant agrees to consent to <a href="https://www.openroadlending.com/us/consent-electronic-documents/" target="_blank">Electronic Disclosures</a> and to use electronic records and signatures. I consent to receive disclosures electronically.`,
				required: true,
				consents: ['eConsent']
			},
			//Skip offer/Cash apps
			consent3_individual: {
				text: ``,
				required: true,
				consents: ['eConsent']
			},
			//preapproval
			consent4_individual: {
				text: ``,
				required: true,
				consents: ['Marketing', 'eConsent', 'SoftInQuiry']
			},

			consent1_joint: {
				text: `By providing information about yourself in this pre-qualification and selecting the box to the left,
            you consent, acknowledge, and agree to the following:
        <ul>
        <li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
        <li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
        <li>You are providing express written consent under the Fair Credit Reporting Act on behalf of yourself and one or more co-borrowers
        to authorize ABC to obtain your and one or more co-borrowers credit profile from credit reporting agencies. You authorize ABC to obtain
        such information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is not a
        final application for credit.</li>
        </ul>`,
				required: true,
				consents: ['eConsent', 'SoftInQuiry']
			},
			consent2_joint: {
				text: 'By selecting the box to the left, you agree that completing and submitting information in this pre-qualification on behalf ' +
					'of yourself and one or more co-borrowers indicates your intent to apply for joint credit.',
				required: false,
				consents: ['Marketing']
			},
			//skipoffer joint
			consent3_joint: {
				text: '',
				required: true,
				consents: ['eConsent']
			}

		},
		teller: {
			consent1_individual: {
				text: `By providing the information in this application and clicking on the "Click to Complete and Start Saving!" button and the
				“I Agree to these Terms and Conditions” box, the applicant and any co-applicant ("I," "me," and "my") each agree that: (1) I received and read the
				<a href="https://www.openroadlending.com/us/state-specific-disclosures/" target="_blank">State Disclosures</a> and the
				<a href="https://www.openroadlending.com/us/privacylegal/" target="_blank">OpenRoad Lending Privacy Policy</a>;
				(2) I received, read, and agree to the <a href="https://www.openroadlending.com/us/consent-electronic-documents/" target="_blank">Consent for Electronic Disclosures</a>
				and all other <a href="https://www.openroadlending.com/terms-conditions/" target="_blank">Terms and Conditions</a> for this application shown above; (3) I authorize OpenRoad Lending to pass my application to other Lenders who may be able to assist
				me in refinancing; (4) I am granting OpenRoad Lending and its Lender partners permission to access my credit report to determine if I qualify for
				refinancing my existing auto loan, and I understand that I could have multiple inquiries on my credit report through this process; and (5) I
				provide express consent to receive prerecorded calls/texts or live agent calls regarding this application and financial services or other credit
				related offers from OpenRoad Lending or one if its third party lenders or affiliates at any number I have provided on this application
				(consent to be contacted is not a condition to purchase services).`,
				required: true,
				consents: ['Marketing', 'SoftInQuiry']
			},
			consent2_individual: {
				text: `Applicant agrees to consent to <a href="https://www.openroadlending.com/us/consent-electronic-documents/" target="_blank">Electronic Disclosures</a>
				and to use electronic records and signatures. I consent to receive disclosures electronically.`,
				required: false,
				consents: ['eConsent']
			},
			//Skip offer/Cash apps
			consent3_individual: {
				text: ``,
				required: true,
				consents: ['eConsent']
			},
			//preapproval
			consent4_individual: {
				text: ``,
				required: true,
				consents: ['Marketing', 'eConsent', 'SoftInQuiry']
			},

			consent1_joint: {
				text: `By providing information about yourself in this pre-qualification and selecting the box to the left,
            you consent, acknowledge, and agree to the following:
        <ul>
        <li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
        <li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
        <li>You are providing express written consent under the Fair Credit Reporting Act on behalf of yourself and one or more co-borrowers
        to authorize ABC to obtain your and one or more co-borrowers credit profile from credit reporting agencies. You authorize ABC to obtain
        such information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is not a
        final application for credit.</li>
        </ul>`,
				required: true,
				consents: ['eConsent', 'SoftInQuiry']
			},
			consent2_joint: {
				text: 'By selecting the box to the left, you agree that completing and submitting information in this pre-qualification on behalf ' +
					'of yourself and one or more co-borrowers indicates your intent to apply for joint credit.',
				required: false,
				consents: ['Marketing']
			},
			//skipoffer joint
			consent3_joint: {
				text: '',
				required: true,
				consents: ['eConsent']
			}
		}

	},
	defaultLoanAmount: 500,
	isCoBorrowerRequired: true,
	isCitizenshipRequired: true,
	existingMemberFields: {
		lastName: true,
		dateOfBirth: true,
		zipCode: false,
		last4SSN: true,
		ssn: false,
		visible: true,
		firstName: false,
		email: false,
		memberNumber: false,
		existingMemberText: ''
	},
	isPermanentResidentRequired: false,
	defaultRateType: 'fixed',
	preEligibilityCheck: false,
	relationshipToApplicant: false,
	vehicleUndecided: true,
	residenceStatusOnAbout: true,
	hardInquiryConsent: 'on-page', //modal
	hardInquiryConsentText: 'The lender(s) receiving your application will be obtaining a consumer report from ' +
		'a credit report agency. They will inform you of the name and address of the credit reporting agency. ' +
		'In addition, subsequent reports may be requested or used by the lender(s) in connection with ' +
		'your application. By clicking this button you are giving consent to submit to one or more lender ' +
		'who will do a hard credit pull, which may affect your credit file.',
	hardInquiryConsentText_joint: 'You authorize OpenRoad Lending to obtain your credit report and Joint Applicant\'s credit report   from a credit reporting company. You acknowledge that this ' +
		'is an application for credit and authorize OpenRoad Lending to obtain such information solely to evaluate your credit application. You are ' +
		'also consenting to allow OpenRoad Lending to contact you and offer you savings and benefits on other products.',
	underwritingStatusMessages: {
		approved: {
			title: 'Congratulations! Your application is now approved.',
			message: 'Our team will reach out to you within the next business day with next steps. Thank you for your membership in OpenRoad Lending .'
		},
		pending: {
			title: 'CONGRATULATIONS!!',
			message: 'You are one step closer to achieving your goal! Our award-winning team will be reaching out to you to finalize your offer. We look forward to speaking with you soon!'
		},
		decline: {
			title: 'CONGRATULATIONS!!',
			message: 'You are one step closer to achieving your goal! Our award-winning team will be reaching out to you to finalize your offer. We look forward to speaking with you soon!'
		},
		default: {
			title: 'CONGRATULATIONS!!',
			message: 'You are one step closer to achieving your goal! Our award-winning team will be reaching out to you to finalize your offer. We look forward to speaking with you soon!'
		}
	},
	crossSellOffersPage: {
		title: 'Congratulations!! We found additional personalized pre-qualified offers for you.',
		message: ''
	},
	footerConfig: {
		footerTop: {
			BGColor: '#FFf',
			FontColor: '#000'
		},
		footerBottom: {
			BGColor: '#34b449',
			FontColor: '#FFF',
			copyRight: '.',
			phone: '888-536-3024'
		}
	},
	ssnPrompt: true,
	mailingAddress: false,
	minHousingCost: 350,
	iovationIntegration: false,
	defaultIncome: 0,
	minMax: {
		loanAmount: {
			min: 5000,
			max: 9999999
		}
	},
	// fraudCheckOnFinalSubmit: true,
	poboxAllowed: false,
	promoCode: true,
	showHaveOfferCodeText: false,
	membershipEligibilityCriteria: {
		visible: false,
		membershipString: '',
		familyMember: true,
		sponsorOrganization: false,
		sponsorOrganizationType: 'search',
		live: true,
		work: true,
		worship: true,
		study: true
	},
	redeemCodeFields: {
		lastName: true,
		zipCode: true,
		ssn: false,
		dob: false
	},
	membershipEligibilityOptions: [
		{value: 'FAMILY_MEMBER', label: 'Someone in my family is a member'},
		{value: 'LIVING_AREA', label: 'I live in an eligible county (see county list below)'},
		{value: 'WORK_AREA', label: 'I work in an eligible county (see county list below)'},
		{value: 'WORSHIP_AREA', label: 'I worship in an eligible county (see county list below)'},
		{value: 'LEARN_AREA', label: 'I study in an eligible county (see county list below)'}
	],
	residenceStatusList: [
		{value: 'OWN_MORTGAGE', label: 'Mortgage'},
		{value: 'OWN_FREE_CLEAR', label: 'Own Free and Clear'},
		{value: 'RENT', label: 'Rent'},
		{value: 'LIVING_WITH_PARENTS', label: 'Living with Parents'},
		{value: 'OTHER', label: 'Other'}
	],
	employmentTypeList: [
		{name: 'Employed', value: 'F'},
		{name: 'Self Employed', value: 'S'},
		{name: '1099 Employee', value: 'EMPLOYEE_1099'},
		{name: 'Active Duty Military', value: 'M'},
		{name: 'Disabled', value: 'D'},
		{name: 'Retired', value: 'R'},
		{name: 'Other', value: 'O'},
	],
	customQuestions: false,
	loanPurpose: {
		R: {
			id: 'R',
			title: 'Vehicle Refinance',
			productName: 'AUTO',
			isMLARequired: false,
			isRefinance: true,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			fraudCheckAPICall: '',
			hideAdditionalInfoStep: true,
			aboutYouPage: {
				employmentType: true,
				employmentLength: true,
				additionalIncome: true,
				employerName: true,
				jobTitle: true,
				residenceLength: true
			},
			preScreenPage: {
				grossAnnualIncome: true,
				rentMortgagePmt: true
			},
			addOnProducts: {
				visible: false,
				productAtOffer: false
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				plateToVin: true,
				required: true,
				multiselect: false,
				code: 'AUTO',
				tradeLineDisplay: 'Auto'
			},
			collateral: {
				required: true,
				plateToVin: true,
				type: 'AUTO',
				yearMakeModelSelection: true,
				years: '25', // all, new, old, 10 = 10+ years, 20 = 20+ years etc
				maxMileage: 200000
			},
			offer: {
				slider: false,
				downPayment: false,
				saving: true
			},
			phone: '888-536-3024',
			minAge: 18,
			minAnnualIncome: 0,
			maxAnnualIncome: 25000,
			minLoanAmount: 500,
			maxLoanAmount: 75000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: 'retailValue',
			defaultLoanAmount: 22500,
			othersOnTitle: false,
			othersOnTitlePhone: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: false,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: false, // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: false, // true,false,'optional'
					last4ssn: false,
					workPhone: false,
					desiredLoanAmount: false,
					desiredTerm: false
				},
				employmentHistoryDuration: 0,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false,
					driverLicense: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false,
					driverLicense: false
				},
				declarationQuestions: {
					outstandingJudgements: true,
					bankrupt: true,
					foreClosed: true,
					lawsuit: true,
					obligatedLoanForeclosure: true,
					delinquentFederalDebt: true,
					payAlimony: true,
					downPaymentBorrowed: true,
					coMakerOrEndorser: true,
					propertyAsPrimaryResidence: true,
					ownershipInterest: true
				}
			},
			crossSellPageSubTitle: 'Save {{monthlySavings}} on your current loan payment.',
			defaultOfferVariationObject: {
				purchaseIntroAPR: 40,
				purchaseIntroPeriod: 20,
				purchaseAPR: 10,
				balanceTransferIntroAPR: 15,
				balanceTransferIntroPeriod: 23,
				balanceTransferAPR: 12,
				cashAdvanceAPR: 5,
				penaltyAPRDesc: 16,
				foreignTransactionDesc: 21,
				returnedPaymentFeeDesc: 13,
				latePaymentFeeDesc: 2
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{type: 'W2', required: true}
			],
			tradeInVehicle: false
		}
	}
};
