import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from "@angular/common";

import { BehaviorSubject, Subscription } from "rxjs";

import {
	APP_CONFIG, APP_TEXT_CONFIG,
	ApplicantService,
	ApplicationService,
	AuthenticationService,
	SharedKeyDataService
} from "@creditsnap/app-config";
import {
	ApplicationModel,
	AutoLoanPurpose,
	Credential,
	KeyIdentifierModel,
	ProgressButtonOptions,
	SearchModel
} from "@creditsnap/data-models";

@Component({
	selector: "m-my-offer",
	templateUrl: "./my-offer.component.html",
	styleUrls: ["./my-offer.component.scss"]
})
export class MyOfferComponent implements OnInit, OnDestroy {

	@ViewChild("f") f: NgForm;

	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	redirectedToHere: boolean;
	search: SearchModel | any;
	message: string = "";
	minDate = new Date();
	maxDate = new Date();
	keyIdentifier: KeyIdentifierModel;
	// This is used to unscribe the subscribed events on detroy.
	searchSub: Subscription;
	errorMessage: boolean;
	hasMoreApps: boolean;
	applications: ApplicationModel[];
	selectedApplicationId: number;
	redirectingMessage: boolean;
	public model: any = { grant_type: "", username: "", password: "" };

	@ViewChild("mScrollTopReentry") elScrollTopReentry: ElementRef;

	subscription: Subscription;

	barButtonOptions: ProgressButtonOptions = {
		active: false,
		text: "Find My Offer",
		buttonColor: "primary",
		barColor: "#C1D72E",
		raised: true,
		stroked: false,
		mode: "indeterminate",
		value: 0,
		disabled: false
	};
	redeemPage = false;

	constructor(
		private elRef: ElementRef,
		@Inject(APP_CONFIG) public appConfig: any,
		@Inject(APP_TEXT_CONFIG) public appTextConfig: any,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private applicationService: ApplicationService,
		private applicantService: ApplicantService,
		private sharedkeyDataService: SharedKeyDataService,
		private authenticationService: AuthenticationService,
		private datePipe: DatePipe,
		private title: Title) {
		this.redirectedToHere = false;
		this.errorMessage = false;
		this.hasMoreApps = false;
		this.subscription = sharedkeyDataService.keyIdentifier$.subscribe((data) => {
			this.keyIdentifier = data;
			this.allPreviousAsyncEventsCompleted(data);
		});
		this.redirectingMessage = false;
	}

	ngOnInit() {
		if (this.router.url.includes("/redeem")) {
			this.redeemPage = true;
		}

		this.search = new SearchModel();
		this.activatedRoute.queryParams.subscribe(params =>  {
			if(params['offercode']) {
				this.search.offerCode = params['offercode'];
			}
			if(params['lastname'] && this.appConfig.redeemCodeFields.lastName) {
				this.search.lastName = params['lastname'];
			}
			if(params['zip'] && this.appConfig.redeemCodeFields.zipCode) {
				this.search.zipCode = params['zip'];
			}
			if(params['last4ssn'] && this.appConfig.redeemCodeFields.ssn) {
				this.search.last4ssn = params['last4ssn'];
			}
			if(params['dob'] && this.appConfig.redeemCodeFields.dob) {
				this.search.dob = params['dob'];
			}
		});

		this.loadingSubject.next(true);
		console.log("ReentryComponent ngOnInit => ", this.keyIdentifier, new Date().getTime());
		if (!this.sharedkeyDataService.isIdentifierAnnounced(this.keyIdentifier)) {
			this.keyIdentifier = this.sharedkeyDataService.initializeIdentifier(this.keyIdentifier);
			console.log("ReentryComponent keyidentifier initialized => ", this.keyIdentifier, new Date().getTime());
		}
		this.activatedRoute.queryParams.subscribe(params => {
			console.log("query params =>", params);
			if (params["redirect"]) {
				this.redirectedToHere = true;
			}
		});
		// allow dob if it is greater than 18 years old
		this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
		this.minDate.setFullYear(this.minDate.getFullYear() - 85);
		this.title.setTitle((this.redeemPage) ? "Redeem" : "My Offer");
		this.loadingSubject.next(false);
	}

	allPreviousAsyncEventsCompleted(data: any) {
		console.log(" ReentryComponent allPreviousAsyncEventsCompleted =>", data, new Date().getTime());
	}

	startProgressBarAction() {
		console.log(" startProgressBarAction .. started");
		this.barButtonOptions.active = true;
		this.loadingSubject.next(true);
		this.barButtonOptions.text = "Finding Your Offer...";
		// this.barButtonOptions.mode = 'indeterminate';
	}

	completeProgressBarAction() {
		console.log(" completeProgressBarAction .. completed");
		this.barButtonOptions.text = "Find My Offer";
		this.barButtonOptions.active = false;
		this.loadingSubject.next(false);

	}

	onSubmit(offerCode: boolean) {
		this.startProgressBarAction();
		this.errorMessage = false;

		// this.search.dob = this.datePipe.transform(this.search.dateOfBirth, 'MM/dd/yyyy');
		console.log(" Search for the applications => ", this.search);

		const params: any = {
			offerCode: this.search.offerCode.trim()
		};

		if (this.appConfig.redeemCodeFields.lastName) {
			params.lastName = this.search.lastName;
		}

		if (this.appConfig.redeemCodeFields.dob) {
			params.dob = this.datePipe.transform(this.search.dateOfBirth, "MM/dd/yyyy");
		}

		if (this.appConfig.redeemCodeFields.ssn) {
			params.last4SSN = this.search.last4SSN.trim();
		}

		if (this.appConfig.redeemCodeFields.zipCode) {
			params.zipCode = this.search.zipCode;
		}
		console.log(params);
		let searchApi = this.applicationService.searchPreApprovalApplicationWithOfferCode(params);
		if (!offerCode) {
			this.search.dob = this.datePipe.transform(this.search.dateOfBirth, "MM/dd/yyyy");
			const searchParams: SearchModel = { ...this.search };
			delete searchParams.offerCode;
			delete searchParams.dateOfBirth;
			searchApi = this.applicationService.searchPreApprovalApplicationWithoutOfferCode(searchParams);
		}
		searchApi.subscribe(result => {
			console.log(result);
			if (result.approvalApplicationVOS.length > 0) {
				this.errorMessage = false;
				this.applicationService.approvalApplicationData = result.approvalApplicationVOS[0];
				this.keyIdentifier.preApprovalApplicationId = result.approvalApplicationVOS[0].preApprovalApplicationId;
				this.keyIdentifier.campaignType = result.approvalApplicationVOS[0].campaignType;
				this.keyIdentifier.applicationId = result.approvalApplicationVOS[0].applicationId;

				this.keyIdentifier.referralCode = "PRESCREEN";
				this.keyIdentifier.subId = this.search.offerCode;
				this.sharedkeyDataService.announceIdentifierChanges(this.keyIdentifier);
				if (this.keyIdentifier.applicationId || this.keyIdentifier.applicationId > 0) {
					this.router.navigate(["myapp"]);
				} else {
					this.router.navigate(["preapproval-app"]);
				}
			} else {
				this.errorMessage = true;
			}
			this.completeProgressBarAction();
		}, error => {
			console.log(error);
		});
	}


	// @ts-ignore
	nextScreen(app: ApplicationModel) {
		console.log("nextScreen  app selected moving to next screen => ", app);
		if (!app) {
			this.errorMessage = true;
			console.log("something happened.... staying back on the same screen");
			return false;
		}
		this.keyIdentifier.updateKeyIdentifier(app);
		console.log("1 copyKeyIdentifier started => ", this.keyIdentifier, new Date().getTime());
		// console.log(' Able to identify the application and moving to the next screen', this.appStateList, this.action);
		const keys = this.sharedkeyDataService.performApplicationNextAction(this.keyIdentifier);
		console.log("Moving to the next step =>", keys);
		this.keyIdentifier.errorCode = keys.errorCode;
		this.sharedkeyDataService.announceIdentifierChanges(this.keyIdentifier);
		if (keys.screenAction === "vendor" && !(app.clientApplicationNumber === undefined ||
			app.clientApplicationNumber === null)) {

		} else {
			this.router.navigate([keys.navigationAction], { relativeTo: this.activatedRoute });
		}
		this.completeProgressBarAction();
	}


	GetApplicationDetails(_appId: number) {
		console.log("GetApplicationDetails => ", _appId);
		this.errorMessage = false;
		this.searchSub = this.applicationService.findApplication(_appId).subscribe(res => {
			this.keyIdentifier.updateApplicant(res.applicants);
			this.nextScreen(res);
		}, error => {
			this.sharedkeyDataService.mapErrorCodes(error, this.keyIdentifier);
			this.completeProgressBarAction();
		});
	}

	ngOnDestroy() {
		if (this.searchSub) {
			this.searchSub.unsubscribe();
		}
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		this.loadingSubject.unsubscribe();
	}

	loanPurposeDescription(key: any) {
		return this.sharedkeyDataService.GetObjectPropertyValue(AutoLoanPurpose, key);
	}

	scrollToField() {
		const invalid = this.elRef.nativeElement.querySelector(".ng-invalid");
		if (invalid) {
			invalid[0].scrollIntoView({ behavior: "smooth" });
		}
	}

	generateTokenWithApp(applicationId: number) {
		console.log("generateTokenWithApp: application created, waiting for token =>", this.search);
		const credential: Credential = new Credential();
		credential.username = applicationId.toString();
		credential.password = this.search.lastName + this.search.dob + this.search.email; // + this.search.last4SSN
		credential.grant_type = "password";
		this.authenticationService.authenticate(credential).subscribe(result => {
			console.log("application created and token generated =>", result);
			//  this.authenticationService.saveAccessData(result);
			this.GetApplicationDetails(applicationId);
		}, err => {
			console.log("generateTokenWithApp => ", err);
			this.errorMessage = true;
			this.completeProgressBarAction();
		});
	}
}
