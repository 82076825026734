<div class="product-section custom-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="m-cs-subheader form-header">
          <div class="d-flex align-items-center justify-content-center">
            <div *ngIf="redirectedToHere; else directVisit" class="m--font-danger m-cs-subheader_second-title">
              Oops! Something unexpected happened. Please give us the following information to get back to where
              you were.
            </div>
            <ng-template #directVisit>
              <div class="mx-auto text-center" id="mr-auto" >
                <h2 class="m-cs-subheader__title">{{appTextConfig.myOfferPage.title}}</h2>
                <p class="m-cs-subheader_second-title">{{appTextConfig.myOfferPage.subTitle}} </p>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="product-section-box">
			<form #f="ngForm" (ngSubmit)="f.form.valid" class="m-form m-form--group-seperator-dashed"
				  name="form" novalidate>
				<div class="m-form__group">
					<div class="row mat-form-border">
						<div class="col-md-7 ">
							<div class="mat-form-space">
								<mat-form-field class="mat-form-field-fluid">
									<mat-label>Please enter your savings ID:</mat-label>
									<input [(ngModel)]="search.offerCode" #offerCode="ngModel"
										   [ngClass]="{ 'is-invalid': f.submitted && offerCode }" autocomplete="off" autofocus
										   matInput name="offerCode"
										   required type="text" upperCase>

									<mat-error>{{appTextConfig.myOfferPage.offerCode}} is required</mat-error>
								</mat-form-field>
								<p class="saving-id-text fs-6">
									(Don't have it? Don't worry. <a routerLink="/" routerLinkActive="active">Click here</a> to continue)
								</p>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6 mat-form-border" *ngIf="appConfig.redeemCodeFields.ssn">
							<div class="mat-form-space">
								<mat-form-field class="mat-form-field-fluid">
									<mat-label>Last 4 digits of your SSN</mat-label>
									<input matInput type="tel" name="last4SSN" #last4SSN="ngModel" min-max
										   [(ngModel)]="search.last4SSN"
										   maxlength="4" minlength="4" required>
									<mat-error *ngIf="last4SSN.hasError('required') || last4SSN.hasError('minlength')">Please enter last 4 digits of your
										SSN
									</mat-error>
									<mat-error *ngIf="last4SSN.hasError('min-max')">Please enter last 4 digits of your
										SSN
									</mat-error>
								</mat-form-field>
							</div>
						</div>

						<div class="col-sm-6 mat-form-border" *ngIf="appConfig.redeemCodeFields.lastName">
							<div class="mat-form-space">
								<mat-form-field class="mat-form-field-fluid">
									<mat-label>Last Name</mat-label>
									<input #lastName="ngModel" [(ngModel)]="search.lastName" [ngClass]="{ 'is-invalid': f.submitted && lastName }" autocomplete="off"
										   autofocus matInput name="lastName" required
										   type="text">
									<mat-error>Last name required</mat-error>

								</mat-form-field>
							</div>
						</div>
						<div *ngIf="appConfig.redeemCodeFields.dob" class="col-sm-6 mat-form-border">
							<div class="mat-form-space">
                <creditsnap-date name="dateOfBirth" [label]="'Date of birth (mm/dd/yyyy)'"
                                 [minDate]="minDate" [maxDate]="maxDate"
                                 [(ngModel)]="search.dateOfBirth"></creditsnap-date>
							</div>
						</div>
						<div *ngIf="appConfig.redeemCodeFields.zipCode" class="col-sm-6 mat-form-border">
							<div class="mat-form-space">
								<mat-form-field class="mat-form-field-fluid">
									<mat-label>Zip</mat-label>
									<input #zipCode="ngModel" [(ngModel)]="search.zipCode" matInput name="zipCode"
										   required type="tel"/>
									<mat-error>Zip must be 5 digits</mat-error>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="errorMessage" class="m-form__group">
					<div class="row">
						<div class="col-sm-12">
							<h4 class="m--font-danger">Sorry, we cannot find that {{appTextConfig.myOfferPage.offerCode}}.</h4> Please try re-entering or
							<a routerLink="/" routerLinkActive="active">click here</a>
							to continue without a code.
						</div>
					</div>
				</div>
				<div #mScrollTopReentry class="scrolling-div"></div>

				<div class="m-form__actions mt-3">
					<mat-bar-button (click)="scrollToField()" (tsSubmitIfValid)="onSubmit(true)" [options]="barButtonOptions"
									class="btn pb-3 pb-md-0">
						Find My Offer
					</mat-bar-button>
				</div>
			</form>
        </div>
      </div>
    </div>
  </div>
</div>




