import {Component, HostBinding, Inject, OnInit} from '@angular/core';
import {APP_CONFIG, APP_ENV_CONFIG} from "@creditsnap/app-config";
import {BehaviorSubject} from "rxjs";

@Component({
	selector: 'creditsnap-orl-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	@HostBinding('class') classes = 'm-grid__item m-footer';
	footerContainerClass$: BehaviorSubject<string> = new BehaviorSubject('');
	currentYear = new Date().getFullYear();
	institutionFooterLogo: string = this.env.s3Images + this.env.institutionId + '/logo-inverse.png';

	constructor(
		@Inject(APP_CONFIG) public appConfig: any,
		@Inject(APP_ENV_CONFIG) public env: any,
	) {
	}

	ngOnInit(): void {
	}

}
