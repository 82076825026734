export const text: any = {
	MESSAGES: {
		PROVISIONS: {
			'THANKYOU-DECLINE': {
				TITLE: '<h2>Thank you for applying with OpenRoad Lending!</h2>',
				MESSAGE: '<p>We are still working on processing your application in order to find the best available option.</p>'+
					'<p>We will get back to you shortly with your loan decision and will notify you via email once we have completed processing your application.</p>'+
					'<p>Be sure to check your email for specific instructions on how to view your loan decision.</p>'+
					'<p>Your Application ID is {{APPLICATION_ID}}</p>'
			},
			THANKYOU: {
				TITLE: '<h2>Thank you for applying with OpenRoad Lending!</h2>',
				MESSAGE: ''
			},
		},
		OFFERS: {
			LENDER_APPROVED_NO_RATES: {
				TITLE: 'Thank you for completing the application!',
				MESSAGE: 'Thank you for submitting your application. We need additional information before ' +
					'you can see your offer. Please contact your {{institutionName}} ' +
					'Member Services Representative at {{phoneNumber}} so that we can get you the loan that meets your needs.'
			},
			NO_BUREAU_REPORT: {
				TITLE: '',
				MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
			},
			NO_BUREAU_HIT_COBORROWER: {
				TITLE: '',
				MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
			},
			NO_OFFER: {
				TITLE: '',
				MESSAGE: 'We appreciate your inquiry, but we do not have any potential offers to present at this time.'
			},
			PEND: {
				TITLE: '',
				MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
			},
			CROSS_SELL_DISCLOSURE: {
				MESSAGE: ''
			},
			NO_OFFER_NO_HIT: {
				TITLE: 'We are still searching for the best offer for you.',
				MESSAGE: 'We were not able to locate an offer on our initial review but may have other loan options available!'
			},
			NO_OFFER_HARD_DECLINE: {
				TITLE: 'We are still searching for the best offer for you.',
				MESSAGE: 'We were not able to locate an offer on our initial review but may have other loan options available!'
			},
			NO_OFFER_FRAUD_DECLINE: {
				TITLE: 'We are still searching for the best offer for you.',
				MESSAGE: 'We were not able to locate an offer on our initial review but may have other loan options available!'
            },
            FRAUD: {
				TITLE: 'We are still searching for the best offer for you.',
				MESSAGE: 'We were not able to locate an offer on our initial review but may have other loan options available!'
            },
			NO_OFFER_FOUND: {
				TITLE: 'We are still searching for the best offer for you.',
				MESSAGE: 'We were not able to locate an offer on our initial review but may have other loan options available!'
			},
			NO_OFFER_SOFT_DECLINE: {
				TITLE: 'We are still searching for the best offer for you.',
				MESSAGE: 'We were not able to locate an offer on our initial review but may have other loan options available!'
			},
			NO_OFFER_PRE_FAIL: {
				TITLE: 'We are still searching for the best offer for you.',
				MESSAGE: 'We were not able to locate an offer on our initial review but may have other loan options available!'
			},
			NO_OFFER_WITH_LTV: {
				TITLE: 'We are still searching for the best offer for you.',
				MESSAGE: 'We were not able to locate an offer on our initial review but may have other loan options available!'
			},
			NO_OPTIONS: {
				MESSAGE: 'Thank you for submitting your request. At this time, we are unable to find a suitable offer for you.'
			},
			PAYMENT_EXAMPLE: {
				MESSAGE: 'Payment Example: Monthly payments for a $10,000 loan at 3.19% APR with a term of 4 ' +
					'years would result in 48 monthly payments of $222.18.'
			},
			APR_LEGAL_NOTE: {
				MESSAGE: 'APR = Annual Percentage Rate. The specific rate and term will be dependent upon your credit ' +
					'worthiness, amount financed and other factors.  Rates and terms are subject to change without prior ' +
					'notice. Other restrictions apply.'
			},
			APR_LEGAL_NOTE_Non_Auto: {
				MESSAGE: 'APR = Annual Percentage Rate. The specific rate and term will be dependent upon your credit worthiness, ' +
					'amount financed and other factors. Rates and terms are subject to change without prior notice. ' +
					'Other restrictions may apply.'
			},
			PRE_QUALIFIED_OFFER: {
				MESSAGE: 'The Pre-Qualified offers presented above are based on the information you provided and information from ' +
					'your credit file. These are initial, conditional offers that are subject to change, based on the completion of a ' +
					'final credit application, verification of the information you provided, your ability to repay, and other conditions. ' +
					'The offers are non-binding, are not guaranteed, and are not a commitment to lend'
			},
			DECLINE: {
				TITLE: '<h2>Thank you for applying with OpenRoad Lending!</h2>',
				MESSAGE: '<p>We are still working on processing your application in order to find the best available option.</p>'+
					'<p>We will get back to you shortly with your loan decision and will notify you via email once we have completed processing your application.</p>'+
					'<p>Be sure to check your email for specific instructions on how to view your loan decision.</p>'+
					'<p>Your Application ID is {{APPLICATION_ID}}</p>'
			},
		},
		CASH_APPLICATION: {
			TITLE: 'Thank you for completing the application!',
			MESSAGE: 'Answer the call from OpenRoad Lending so we can drive you into a better loan.'
		},
		PROVISIONING_COMPLETE: {
			TITLE: 'You are all set.',
			MESSAGE: 'Answer the call from OpenRoad Lending so we can drive you into a better loan.'
		},
		FINANCIAL_SUBMITTED: {
			TITLE: 'Thank you!',
			MESSAGE: 'Answer the call from OpenRoad Lending so we can drive you into a better loan.'
		},
		PRE_FINANCIAL_SUBMITTED: {
			TITLE: 'Thank you for completing the application!',
			MESSAGE: 'Answer the call from OpenRoad Lending so we can drive you into a better loan.'
		},
		FINANCIAL_SUBMITTED_FAILED: {
			TITLE: 'Thank you for completing the application!',
			MESSAGE: 'Answer the call from OpenRoad Lending so we can drive you into a better loan.'
		},
		EXCEPTION: {
			TITLE: 'Oops, something unexpected happened!',
			MESSAGE: 'Our expert Member Service Representatives will be able to take care of you. Please call ' +
				'{{phoneNumber}} to speak to your dedicated {{institutionName}}Member Service Representative.'
		},
		DISCLOSURE: {
			FINAL: {
				CONSENT_1: 'You are providing written consent under the Fair Credit Reporting Act to authorize {{institutionName}} to',
				CONSENT_2: 'obtain your credit report with a hard credit inquiry ',
				CONSENT_3: ' from credit reporting agencies. You acknowledge that this is an application for credit and authorize ' +
					'{{institutionName}} to obtain such information solely to evaluate your credit application. ' +
					'By clicking this box, you consent, acknowledge, and agree that {{institutionName}} can process your final application for credit.' +
					'You are also consenting to allow us offer you savings and benefits on other products.'
			},
			SOFT_INQUIRY: {
				CONSENT_1: ' You are providing express written consent under the Fair Credit Reporting Act to authorize ' +
					'{{institutionName}} to obtain your credit profile from credit reporting agencies. You authorize ' +
					'{{institutionName}} to obtain such information solely to evaluate your eligibility for certain loan product ' +
					'pre-qualification offers and other cross-sell offers and acknowledge that this is not an application for credit.',
				CONSENT_UPTO_LENDER: '',
				CONSENT_PRIVACY: 'You agree that you received {{institutionName}} <a ' +
					'href="" target="_new">Privacy Policy</a>. ' +
					'You also verified that you can read and print or keep these electronic documents and you ' +
					'certify that your selections and information in this form are true, correct and complete.',
				CONSENT_MARKETING: 'I hereby consent to receive email, SMS or other marketing communications from {{institutionName}}.' +
					'I further expressly agree that {{institutionName}} may contact me at the calling numbers providing including ' +
					'my cellular number, via alive, auto dialer, prerecorded telephone call, text chat or email message. ' +
					'I further understand that my telephone company may impose charges on me for these contacts and I am not ' +
					'required to provide this consent to be effective even if my calling numbers or email address are included on ' +
					'ado-not-call list or registry. I understand that my consent to receive calls is not required in order to ' +
					'purchase any goods or services. If you do not want to receive any marketing communications just un-check the box.'
			},
			MARKETING_INQUIRY: {
				CONSENT_MARKETING_1: 'I hereby consent to receive email, SMS or other marketing communications from {{institutionName}}. ' +
					'I further expressly agree that {{institutionName}} ',
				CONSENT_MARKETING_2: ' may contact me at the calling numbers providing including my cellular number, via live, ' +
					'auto dialer, prerecorded telephone call, text, chat or email message. I further understand that my telephone ' +
					'company may impose charges on me for these contacts and I am not required to provide this consent to be effective even if ' +
					'my calling numbers or email address are included on a do-not-call list or registry. I understand that my ' +
					'consent to receive calls is not required in order to purchase any goods or services. ' +
					'If you do not want to receive any marketing communications just un-check the box'
			}
		}
	},
	aboutPage: {
		title: "See your {{pre-qualified}} {{loanPurpose}} offers in as little as 2 minutes.", // See your pre-qualified offers in as little as 2 minutes.
		mobileTitle: "See your {{pre-qualified}} offers in as little as 2 minutes. No impact to your credit score unless you are approved.",
		subTitle: "No impact to your credit score unless you are approved.",  // Completing the form below will not affect your credit score.
		cashApplicationTitle: "Complete the form below for {{title}}",
		cashApplicationSubTitle: "With a Co-Borrower, you can rely on joint income to qualify for better credit options.",
		loanPurposePlaceholder: "What are you interested in?",
		coBorrowerTitle: "Tell me about your Co-Borrower.",
		coBorrowerSubTitle: "With a Co-Borrower, you can rely on joint income to qualify for better credit options.",
		primaryBorrowerTitle: "Complete the Application Below to Get Started with Purchasing Your Vehicle",
		haveOfferCodeText: "Received a mailer from us? Use Offer Code",
		housingCostHint: "Estimate monthly housing cost. Homeowners, include all mortgage payments (first & second mortgages, and home equity loans). Renters, please enter your portion of monthly rent.",
		grossAnnualIncomeHint: "Before tax Gross Monthly Income",
		textBelowSubmitButton: "No credit impact for checking your rates.",
		employmentPageTitle: "Lastly, tell us about your employment",
		addressPageTitle: "Great! Just a little more info"

	},
	offerPages: {
		headlineTitle1: "Congratulations, {{applicantFirstName}}! ", // Congratulations, Iram! Several
		headlineTitle2: "You have pre-qualified offers available. ", // Congratulations, Iram! Several
		ccHeadlineTitle: "You have {{pre-qualified}} card offers available.",
		asLowAs: null,
		termBasedLoanSubTitle: "Select or customize your pre-qualified offer to continue to the next step.",
		preQualified: "These Pre-Qualified offers are based on the information you provided and in your credit file. " +
			"They are initial, conditional offers and are subject to change based on completion of a final application, " +
			"verification of information you provided, and other conditions determined solely by the lender. " +
			"The offers above are not guaranteed and not a commitment to lend.",
		aprLegalNoteTermBasedLoan: "The Annual Percentage Rate (APR) is the yearly rate at which your loan accrues interest. " +
			"Rates will vary based on your credit, loan size and type of collateral is provided. APRs may range " +
			"from 2.99% to 18.00%. Members with good credit with vehicles with low loan to " +
			"values ratios may qualify for the lowest rates. Minimum and maximum loan amounts and APRs offered may vary " +
			"according to state law and are subject to change.",
		aprLegalNoteNotTermBasedLoan: "APR = Annual Percentage Rate. The specific rate and term will be dependent upon your credit rating, " +
			"amount financed and other factors. Rates and terms are subject to change without prior notice; " +
			"other restrictions may apply.",
		aprCCNoteContent: "",
		paymentExample: "Payment Example: Monthly payments for a $10,000 loan at 3.19% APR with a term of 4 " +
			"years would result in 48 monthly payments of $222.18. Total interest paid over full term " +
			"of the loan will be $664.64.",
		additionalLine: "Submit your application today to lock in your rate!",
		disclosureLineTitle: "Why is my offer or payment different from what I received in the mail?",
		disclosureLineDetail: "Your offer may differ due to the information OpenRoad had prior to your completing the application." +
			" Be sure to speak to your Loan Care Agent about your loan and savings options",
		textAboveSliderInstalment: "How much do you want to borrow?",
		textAboveSliderRevolving: "Select your desired line of credit amount",
		discountText: "(Includes {{_offer.achDiscountRate}}% ACH discount)",
		achDiscountLabel: "Do you want save by signing up for automatic payments?",
		ccSchumerBoxBillingText: "Your due date is at least 21 days after we mail your billing statement. " +
			"We will not charge you interest on purchases if you pay your entire new purchase balance by the " +
			"due date each month. We will begin charging interest on cash advances and balance transfers on the transaction date.",
		offerLoadingLongHtml: 'Did you know that on an average refinance transactions saves $75 in monthly payment? <br/>' +
			'Did you know that refinance can be done as soon as 2 months from the time you purchase your car? <br/>' +
			'Did you know that 70% of refinance transactions result in a lower payment? And did you know that customers are OK with higher payment because they save on rate and total interest paid',
		referenceIdText: 'Reference Id:',
	},
	myOfferPage: {
		title: "Welcome! We are happy you are here.",
		subTitle: "Enter your information to find your offers.",
		offerCode: "Savings ID",
		customizeOfferText: `<h4>Speak to one of our Loan Care Agents about your goals for refinancing
		and customized options that best fit what you are looking for.</h4>
		<ul><li>Multiple term options</li><li>The ability to skip a payment</li><li>Adding GAP and/or Extended Warranty protection</li>
		<li>Plus more..</li></ul>`
	},
	tradePage: {
		pageTitle: 'Payoff and/or Collateral. Trade/Collateral Page',
		title: 'Last step before you can see your offers.', // Last step before you can see your offers.
		vehicleInformation: 'Vehicle Information', // Vehicle Information
		identificationNumber: 'Do you have your Vehicle Identification Number (VIN)?', // Do you have Vehicle Identification Number?
		beforeStep: 'Last step before you can see your offers.?', // Last step before you can see your offers.
		loanBalance: 'Current Loan Balance', // Current Loan Balance
		paymentMonthly: 'Monthly Payment', // Monthly Payment
		outstandingBalance: 'Est. Outstanding Balance', // Est. Outstanding Balance
		Change: 'Change', // Change
		informationVehicle: 'Vehicle information', // Vehicle information
		selectedVehicle: 'Your Selected Vehicle', // Your Selected Vehicle
		Mileage: 'Mileage', // Mileage
		accessories: 'Please select vehicle accessories.', // Please select vehicle accessories.
		noTradesScenarioTitle: 'We need some additional information to match up your loan data.',
		loanTitle: 'Current Loan Balance',
		tradeLineTitle: 'Please select a trade-line or "I do not see my {{tradeLineDisplay}} loan in this list" option to enter the current loan details',
		tradeLineDisplayTitle: 'Do you want to transfer balance from another {{tradeLineDisplay}} loan? Select Skip below if you are not transfering balance.',
		tradeLineErrorText: 'Please select a trade-line or "I do not see my {{tradeLineDisplay}} loan in this list" option to enter the current loan details.',
		loanAmountHint: 'Please provide approximate outstanding loan amount. You may find on your current loan statement',
		loanAmountError: ' Please enter a valid Payoff amount value between {{minLoanAmount}} and {{maxLoanAmount}}.',
		customMonthlyPayment: 'Please enter current monthly payment',
		noVehicleDisclosure: 'Choosing this option will give you preliminary loan rates. If you already have a vehicle in mind, enter that vehicle information below to receive more accurate rates.',
		noVehicleDisclosure_refi: 'Choosing this option will give you preliminary loan rates. If you are able to find vehicle information, enter vehicle information below to receive more accurate rates.',
		propertyAddressTitle: 'Update address if your property address is not the same as the address you provided',
		additionalInfoTextLong: "You are almost there! We just need a few details.",
		tradeSelectionTextLong: {
			'R': 'Select the vehicle loan that you want to refinance',
			'R_PROMO': 'Select the vehicle loan that you want to refinance',
		},
	},
	financePage: {
		pageTitle: 'Review and Submit. Final Submit Page',
		headline: 'Review and confirm. We will take care of the rest for you.',
		subHeadline: null,
		mobileHeadline: 'Review and confirm. Leave the rest to us.',
		pendHeadline: 'We are still searching for the best offer for you',//'In order to check for offers with additional lenders, a hard inquiry is needed. Remember that a hard inquiry has only a small impact to your credit score.',
		pendSubHeadline: 'We were not able to locate an offer on our initial review but may have other loan options available!',
		pendSubHeadline2: 'In order to check for offers with additional lenders, a hard inquiry is needed. Remember that a hard inquiry has only a small impact to your credit score',
		employmentLengthHelpTextYear: 'Please enter years of employment.',
		employmentLengthHelpTextMonth: 'Please enter month of employment.',
		noCollateralScenarioTitle: 'The presented offer is without the vehicle value. Please add the collateral information to get the final offer.',
		noOfferStatusTitle: 'No offers available for your selections. Please change your loan amount and/or other selections to see available offers',
		downPayment: '(Estimated. Final down payment amount may vary)',
		applicationCheckboxTitle: 'By clicking the SUBMIT button below, you consent, acknowledge, and agree to the following:',
		finalSubmitLoaderMessage: '',
		finalSubmitSSNHint: 'This information is secure and will help expedite the refinance process.'
	},
	thankyouPage:{
		pageTitle:'<h2>Thank You! Saving Money is Easy with OpenRoad</h2>',
		subheadLine: '',
		html:`<div><h2>Here are your next steps:</h2></div>
		<p>&nbsp;</p>
		<div>Connect with one of our Auto Refi Experts now to finalize your savings and discuss any questions you have about your new loan.
		<p>&nbsp;</p>
		Our expert Loan Care Agents are ready to assist you at <a href="tel:888-409-1903">888-409-1903</a> M-F 8:00am to 7:00pm CST.
		<p>&nbsp;</p>
		<a href="tel:888-409-1903">Click here to call</a> and start saving money on your new car payment!
		</div>
		<p>&nbsp;</p>
		<p>Thanks again for choosing OpenRoad, we look forward to serving you!</p>
		<p>Call now to start saving today!&nbsp;<a href="tel:888-409-1903">888-409-1903</a></p>`
	},
	uwDecisionPage:{
		nextStepsText: `<div><h2>Here are your next steps:</h2></div>
		<p>&nbsp;</p>
		<div>Connect with one of our Auto Refi Experts now to finalize your savings and discuss any questions you have about your new loan.
		<p>&nbsp;</p>
		Our expert Loan Care Agents are ready to finalize your savings. Contact them at <a href="tel:888-409-1903">888-409-1903</a> M-F 8:00am to 7:00pm CST.
		<p>&nbsp;</p>
		<a href="tel:888-409-1903">Click here to call</a> and start saving money on your new car payment!
		</div>
		<p>&nbsp;</p>
		<p>Thanks again for choosing OpenRoad, we look forward to serving you!</p>`,
		nextStepsButton: null,
	}
};
