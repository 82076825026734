import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
	selector: 'input[upperCase]',
})
export class UppercaseDirective {

	constructor(private _el: ElementRef) {
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		if (event.key?.length === 1 && event.key?.match(/^[0-9a-zA-Z]+$/) && !(event.ctrlKey || event.metaKey)) {
			event.target['value'] += event.key.toUpperCase();
			event.preventDefault(); //stop propagation
			//must create a "input" event, if not, there are no change in your value
			const evt = new Event("input", {"bubbles": true, "cancelable": false});
			event.target.dispatchEvent(evt);
		}

	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		if (event.clipboardData) {
			event.target['value'] = (event.clipboardData.getData("text") || '').toUpperCase();
			event.preventDefault(); //stop propagation
			const evt = new Event("input", {"bubbles": true, "cancelable": false});
			event.target.dispatchEvent(evt);
		}
	}

}
